import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.scss';
import { Carousel } from 'react-responsive-carousel';

const resizeSlide = () => {
  setTimeout(function() {
    document.activeElement.blur();
  }, 1500);

  if (window.outerWidth < 769) {
    setTimeout(function() {
      const currentSlide = document.querySelector('li.slide.selected');
      const heightToAdjustTo = `${currentSlide.clientHeight.toString()}px`;
      const wrapper = document.querySelector('.slider-wrapper');
      wrapper.style.height = heightToAdjustTo;
    }, 100);
  }
};

export default function StyledCarousel(props) {
  return (
    <Carousel
      showStatus={false}
      showThumbs={false}
      onChange={resizeSlide}
      swipeScrollTolerance={2}
      infiniteLoop={true}
      transitionTime={750}
    >
      {props.children}
    </Carousel>
  );
}
