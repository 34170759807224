import React from 'react';
import styled from 'styled-components';
import Carousel from './Carousel';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import uuid from 'uuid/v4';

import { ButtonSecondary } from '../components/CustomComponents';

const HIGHLIGHT_CAROUSEL_QUERY = graphql`
  query CarouselQuery {
    Campus: file(relativePath: { eq: "slider-campus.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    BmHome: file(relativePath: { eq: "slider-bm-home.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    BmSign: file(relativePath: { eq: "slider-bm-sign.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Mountain: file(relativePath: { eq: "icon-mountain.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    Bolt: file(relativePath: { eq: "icon-bolt.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    Chair: file(relativePath: { eq: "icon-chair.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    Condo: file(relativePath: { eq: "icon-condo.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    House: file(relativePath: { eq: "icon-house.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    Lights: file(relativePath: { eq: "icon-lights.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    Mountain: file(relativePath: { eq: "icon-mountain.png" }) {
      childImageSharp {
        fixed(width: 75) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

const CarouselWrapper = styled.div`
  position: relative;
  top: -5rem;

  li.slide {
    background-color: #fff;
  }

  .slider-wrapper {
    background-color: #fff;
  }

  @media only screen and (max-width: 768px) {
    li.slide {
      height: 100%;
    }
  }

  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.7);
`;

const Slide = styled.div`
  .slide-img {
    height: 225px;
    width: 100%;
  }

  .slide-content {
    background-color: #fff;
    text-align: left;
    padding: 3rem 2rem;
    position: relative;
  }

  .slide-title {
    margin-top: 0;
  }

  .icons-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .single-icon {
      max-width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
  }

  .slide-button {
    margin-top: 1.5rem;
    width: 100%;
    max-width: 350px;
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    .slide-img {
      background-position: center top !important;
      &:before,
      &:after {
        background-position: center top !important;
      }
    }
  }

  @media only screen and (min-width: 480px) {
    .slide-img {
      height: 275px;
    }
  }

  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;

    > * {
      flex: 1 1 50%;
    }

    .slide-img {
      height: 100%;
    }

    .slide-content {
      padding: 2.5rem 4rem 2.5rem 3rem;
    }
  }

  @media only screen and (min-width: 1080px) {
    .icons-wrapper {
      .single-icon {
        margin: 0.5rem 0.75rem 0;
      }
    }
  }
`;

export default function HighlightCarousel() {
  const {
    Campus,
    BmHome,
    BmSign,
    Bolt,
    Chair,
    Condo,
    House,
    Lights,
    Mountain,
    IconMoney,
    IconPeople,
    IconRibbon,
  } = useStaticQuery(HIGHLIGHT_CAROUSEL_QUERY);

  const slides = [
    {
      img: BmSign,
      heading: 'Conveniently',
      subheading: 'Affordable',
      body:
        'The Bella Montaña community is a residential neighborhood just minutes from campus designed by Cal Poly Partners to give faculty and staff the chance to own a home in San Luis Obispo, and be moments from everything that makes the Central Coast a great place to live.',
      icons: [
        {
          img: Mountain,
          text: 'Beautiful Views',
        },
        {
          img: House,
          text: 'Distinct Interiors',
        },
        {
          img: Chair,
          text: 'Elegant Living Areas',
        },
        {
          img: Lights,
          text: 'Contemporary Finishes & Fixtures',
        },
        {
          img: Bolt,
          text: 'Energy Saving Appliances',
        },
        {
          img: Condo,
          text: 'Priced Well Below SLO Market',
        },
      ],
      button: {
        text: 'About Our Homes',
        destination: '/about',
      },
      dividerIcon: IconMoney,
    },
    {
      img: BmHome,
      heading: 'Beautifully',
      subheading: 'constructed',
      body:
        'The Bella Montaña community was thoughtfully designed and carefully crafted by local Cal Poly supporting professionals in partnership with Cal Poly Partners, and is situated near some of San Luis Obispo’s finest attractions. It offers nearly 70 homes with 10 flexible floor plans in 3 distinct categories, La Cumbre, La Mesa, and La Valle.',
      button: {
        text: 'Home Designs',
        destination: '/home-designs',
      },
      dividerIcon: IconRibbon,
    },
    {
      img: Campus,
      heading: 'Campus',
      subheading: 'community',
      body:
        'As a member of the Cal Poly campus community, your commitment to the success of the university and its students is valued and appreciated. Cal Poly Partners is proud to offer an affordable way to enhance your quality of life, through the availability and dependability of the Bella Montaña community. Bella Montaña is just one way Cal Poly Partners is committed to the success of the university’s faculty, staff, and students.',
      button: {
        text: 'Our Community',
        destination: '/community',
      },
      dividerIcon: IconPeople,
    },
  ];

  const builtSlides = slides.map(slide => {
    let icons = [];

    if (slide.icons) {
      icons = slide.icons.map(icon => {
        const iconKey = uuid();

        return (
          <div key={iconKey} className="single-icon">
            <Img fixed={icon.img.childImageSharp.fixed} />
            <p style={{ textAlign: 'center' }}>{icon.text}</p>
          </div>
        );
      });
    }
    const slideKey = uuid();

    return (
      <Slide key={slideKey}>
        <BackgroundImage
          fluid={slide.img.childImageSharp.fluid}
          className="slide-img"
          fadeIn={false}
        ></BackgroundImage>
        <div className="slide-content">
          <h2
            className="slide-title"
            style={{
              fontFamily: 'Source Sans Pro, sans-serif',
              fontWeight: 700,
              lineHeight: '2.5rem',
            }}
          >
            {slide.heading}
            <br />
            <span
              style={{
                fontWeight: 400,
                textTransform: 'uppercase',
                color: '#428269',
              }}
            >
              {slide.subheading}
            </span>
          </h2>
          <p>{slide.body}</p>
          <div className="icons-wrapper">{icons ? icons : ''}</div>
          <div className="button-wrapper">
            <ButtonSecondary
              to={slide.button.destination}
              className="slide-button"
            >
              {slide.button.text}
            </ButtonSecondary>
          </div>
        </div>
      </Slide>
    );
  });

  return (
    <CarouselWrapper>
      <Carousel>{builtSlides}</Carousel>
    </CarouselWrapper>
  );
}
