import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Link as SmoothLink } from 'react-scroll';

import {
  Row,
  Row10Col,
  Row8Col,
  RowCenter,
  Button,
} from '../components/CustomComponents';
import Layout from '../components/Layout';
import HighlightCarousel from '../components/HighlightCarousel';

const UnstyledHeroImage = ({ fluid, children, className }) => {
  return (
    <BackgroundImage fluid={fluid} className={className} durationFadeIn={50}>
      {children}
    </BackgroundImage>
  );
};

const StyledHeroImage = styled(UnstyledHeroImage)`
  height: calc(100vh + 5rem);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const Row8ColStyled = styled(Row8Col)`
  p {
    text-align: center;
    color: white;
    font-size: 1.15rem;
    font-weight: 700;

    span {
      color: #bd8b13;
    }
  }
`;

const HeroContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100%);
  position: relative;

  padding-top: 125px;

  .down-arrow-link {
    background-color: transparent;
    border: none;
    padding: 0.5rem 1rem;
    img {
      margin-bottom: 0;
    }
  }

  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: 1;

    background: rgba(0, 0, 0, 0.25);
  }
`;

const StyledRow10Col = styled(Row10Col)`
  position: relative;
`;

export const IndexPageTemplate = ({
  title,
  hero_image,
  welcome_image,
  button_text,
  button_destination,
  html,
  backgroundImage,
  downArrow,
}) => (
  <div>
    <div>
      <StyledHeroImage fluid={hero_image.childImageSharp.fluid}>
        <HeroContentWrapper>
          <div style={{ zIndex: 2, marginTop: '-10rem' }}>
            <Row style={{ width: '100%' }}>
              <Row10Col style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <h1 style={{ display: 'none' }}>{title}</h1>
                  <Img
                    fluid={welcome_image.childImageSharp.fluid}
                    fadeIn={false}
                    alt="Welcome to Bella Montaña"
                    style={{ marginBottom: '2rem' }}
                  />
                </div>
              </Row10Col>
            </Row>
            <Row>
              <Row8ColStyled>
                <div
                  dangerouslySetInnerHTML={{
                    __html: html,
                  }}
                />
              </Row8ColStyled>
            </Row>
            <RowCenter>
              <Button to={button_destination}>{button_text}</Button>
            </RowCenter>
            <RowCenter>
              <h2 style={{ color: 'white' }}>
                <SmoothLink
                  activeClass="active"
                  to="scroll-anchor"
                  spy={true}
                  smooth={true}
                  offset={-185}
                  duration={500}
                  className="down-arrow-link"
                >
                  <Img
                    fixed={downArrow.childImageSharp.fixed}
                    alt={'Down Arrow Icon'}
                  />
                </SmoothLink>
              </h2>
            </RowCenter>
          </div>
        </HeroContentWrapper>
      </StyledHeroImage>
    </div>
    <BackgroundImage
      fluid={backgroundImage.childImageSharp.fluid}
      id="scroll-anchor"
    >
      <Row>
        <StyledRow10Col>
          <HighlightCarousel />
        </StyledRow10Col>
      </Row>
    </BackgroundImage>
  </div>
);

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  hero_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  welcome_image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  button_text: PropTypes.string,
  button_destination: PropTypes.string,
  html: PropTypes.any,
  backgroundImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  downArrow: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const IndexPage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout location={location} pageMetaTitle={frontmatter.title}>
      <IndexPageTemplate
        title={frontmatter.title}
        hero_image={frontmatter.hero_image}
        welcome_image={frontmatter.welcome_image}
        button_text={frontmatter.button_text}
        button_destination={frontmatter.button_destination}
        html={html}
        backgroundImage={data.FadedHouse}
        downArrow={data.DownArrow}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        hero_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        welcome_image {
          childImageSharp {
            fluid(maxWidth: 1440, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        button_text
        button_destination
      }
    }
    FadedHouse: file(relativePath: { eq: "faded-houses.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    DownArrow: file(relativePath: { eq: "arrow-down.png" }) {
      childImageSharp {
        fixed(width: 35, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;
